
import { LoginSection } from './components/LoginSection';
import backgroundImg2 from './assets/idoceoteacher.jpg';
import logo from './assets/logoicon.png';
import partnerImg from './assets/PartnerWhite.png';
import './styles/main.css'


function App() {
   return (
   
  
    <div className="App h-screen flex " >
    <div  className="p-12 w-full md:w-1/2 relative">
    <img src = {logo} className="w-60 absolute top-2 left-10" ></img>
    <img src = {partnerImg} className=" w-48 absolute top-0 right-10" ></img>
      <div  className='text-3xl font-extrabold text-left pt-24'>
      iDoceo - Teachers gradebook and planner
      <div className='text-2xl font-bold text-left'>
      Azure AD Management
      </div>
    
     
      </div>
      <div className='text-lg font-light text-left '>
      <br/>
      By following this Sign In process, you can add <strong className='font-bold'>iDoceo</strong> to your <strong className='font-bold'>Azure Active Directory</strong> as an Enterprise App. Once added, you'll have the ability to manage user access by allowing or denying permissions as needed.
      <br/>
      This step is necessary if you want your iDoceo users to connect with <strong class='font-bold'>MS Teams for Education</strong>.
       Once linked, users will have the ability to effortlessly upload and download grades and assessments between iDoceo and MS Teams, as well as add classes to their iDoceo. 

      </div>
    
      <div>
      <br/>
      <br/>

      <LoginSection />
      <br/>
      
      </div>
     
    
      
      

      

    
      </div>
    
    <div className='w-1/2 hidden md:block'>
    <img src = {backgroundImg2} class="h-full w-full object-cover relative items-center text-center" ></img>
    <button type="button" className="absolute top-4 right-4 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"><a href="https://www.idoceo.net/index.php/en/instructions/privacy-policy">Privacy policy </a></button>
     

    </div>
    </div>
  );
}

export default App;
