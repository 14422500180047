import React from "react";

import { useMsal } from "@azure/msal-react";
import { Button } from "semantic-ui-react";


/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/" // redirects the top level app after logout
            });
        }
    }

    return (
        <Button variant="secondary" className="ml-auto w-60 mt-6 ml-auto font-bold p-5 rounded-md bg-red-500 text-white" onClick={() => handleLogout("popup")}>Sign out</Button>
    );
}