import React from "react";
import logo from '../assets/appicon128.png';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Button } from "semantic-ui-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { SignOutButton } from './SignOutButton';


export const LoginSection = () => 
{
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
<div>
      <AuthenticatedTemplate>
      <p className="font-bold text-blue-500">iDoceo has been now successfully added to your Azure AD</p>
      <p className="font-light">You can remove it from your Azure AD in the Enterprise Applications section</p>

      <p><SignOutButton /></p>
  </AuthenticatedTemplate>
  <UnauthenticatedTemplate>
  <p className="font-light">You are not signed in! Please sign in.</p>
  

  </UnauthenticatedTemplate>
        <Button variant="secondary" className="mt-6 ml-auto font-bold bg-blue-600 p-5 rounded-md text-white" onClick={() => handleLogin("popup")}>Add iDoceo to Azure AD</Button>
      <br/>
     
<p className="text-left mt-20">
        <h3 className="font-bold text-xl">Additional info</h3>
        Since iDoceo is an offline app, data is locally stored, encrypted in the user's device.
        <br></br>For additional security, we recommend users to enable the PIN lock in iDoceo (Settings > Screen lock) with Face or Touch ID
        <br/>The permissions below are needed by the app to link with MS Teams. No one but the user of the app will have access to this information
        <br/>You should enable the <strong>Consent on behalf of your organization</strong> option
        <br/>You will find Microsoft's reference of all the available permissions <a className="text-blue-600" href="https://learn.microsoft.com/en-us/graph/permissions-reference"> here </a>



        </p>
      <table className="table-auto mt-12">
      <thead >
    <tr >
      <th className="text-left">Scopes / Permissions</th>
      <th className="text-right">Purpose</th>
      
    </tr>
  </thead>
  <tbody>
    <tr >
      <td className="text-left">EduRoster.ReadBasic</td>

      <td  className="text-right"> Will let the app link current students with MS Teams <br/>or create classes in the app based on those in MS Teams<br/>Fields: Name, Lastname, Student Thumbnail and e-mail<br></br><strong>Read only</strong><br/><br/></td>
    
    </tr>
    <tr>
    
    </tr>
    <tr>
      <td>EduAssignments.ReadWrite</td>

      <td  className="text-right"> Will let the app read and write<br/> assignments between the gradebook and MS Teams<br></br><strong>Read/Write</strong><br></br></td>
    
    </tr>
    <tr></tr>
    <tr>
    
    <td>openid,profile,offline_access</td>
    <td  className="text-right"><br></br> Default permissions required to handle<br/> the login and user profile id of MS Graph API<br/><strong>Read</strong></td>
  
  </tr>
    <tr>
    
    </tr>
  </tbody>
      </table>
      
  
        </div>
    );
}



